import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";

const About: React.FC = () => {
  return (
    <div>
      <section className="about">
        <h1 className="font-bold">About Us</h1>
        <p className="text-lg">
          xHero is aimed at transforming how companies appreciate their
          employees. With advanced technology and user-friendly features, we
          provide a seamless experience for both employees and HR administrators
          in one easy-to-use platform.
        </p>
        <Link
          to={"/contact"}
          className="bg-[#6666FF] hover:bg-[#4014bb] text-white rounded-md w-48 h-12 font-bold flex items-center justify-center">
          Book a Demo
        </Link>
      </section>
      <section className="mission">
        <h1>Our Mission</h1>
        <p>
          To help companies build workplaces where employees are happy to work
        </p>
        <p>
          At xHero, we believe that our employees are our greatest asset. Our
          mission is to create a culture where every individual feels valued,
          appreciated, and recognized for their contributions. We are committed
          to fostering an environment of mutual respect, support, and
          encouragement, where everyone has the opportunity to thrive and grow.{" "}
          <br />
          Through our comprehensive employee recognition program, we aim to
          celebrate achievements, big and small, and to reward outstanding
          performance. We understand that recognition is not only about monetary
          rewards but also about acknowledging hard work, dedication, and
          creativity. <br />
          By prioritizing employee recognition, we not only boost morale and
          motivation but also cultivate a sense of belonging and loyalty among
          our team members. Ultimately, our goal is to create a workplace where
          every employee feels empowered to reach their full potential and where
          their contributions are truly appreciated.
        </p>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="svg">
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
      <section className="story">
        <h3>Built-in Integration</h3>
        <div className="stories">
          <div className="story-txt">
            <p>
              There was a dynamic tech company called xHero. xHero was known not
              only for its cutting-edge technology solutions but also for its
              exceptional corporate culture that revolved around employee
              recognition and rewards. Our company’s philosophy was simple:
              happy employees lead to happy customers, and ultimately, a
              successful business.
            </p>
            <p>
              At the heart of xHero’s culture was the belief that every
              employee, regardless of their position, played a vital role in the
              company's success. This belief was championed by the CEO, who had
              a keen understanding of the importance of employee motivation and
              satisfaction. CEO implemented a comprehensive rewards and
              recognition program that was both innovative and inclusive.
            </p>
          </div>
          <img src={require("../img/meeting.png")} alt="meeting" />
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="values">
        <h1>Our Values</h1>
        <p>
          The values are not just words on the wall; they are lived daily by
          every employee and are integral to the company's identity and
          operations.
        </p>
        <div className="values-list">
          <div className="values-items">
            <img src="/img/dir-icon.png" alt="icon" />
            <h3>Innovation</h3>
            <p>
              we encourage creativity and out-of-the-box thinking, empowering
              employees to explore new ideas and technologies. We believe that
              innovation drives progress and that every employee has the
              potential to contribute to groundbreaking solutions.
            </p>
          </div>
          <div className="values-items">
            <img src="/img/unit.png" alt="icon" />
            <h3>Take Effort</h3>
            <p>
              Teamwork and collaboration are essential. We foster a supportive
              environment where employees are encouraged to share knowledge,
              skills, and experience. By working together, we believe it can
              acheive greater results and overcome any challenge.
            </p>
          </div>
          <div className="values-items">
            <img src="/img/good.png" alt="icon" />
            <h3>Ownership</h3>
            <p>
              We take responsibility for our actions and hold ourselves
              accountable for fulfilling our respective duties.
            </p>
          </div>
        </div>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
      <section className="team">
        <div className="teams">
          <h1>Meet Our Team</h1>
        </div>

        <div className="team-list">
          <div className="team-items">
            <img src={require("../img/man 2.png")} alt="man 2" />
            <h3>Opeyemi Bamiduro</h3>
            <p>
              CEO of xHero, Hybrid work Experience, Programmer, Thinking
              out-of-the-box.
            </p>
          </div>
          <div className="team-items">
            <img src={require("../img/man.png")} alt="man" />
            <h3>Opeyemi Bamiduro</h3>
            <p>
              CEO of xHero, Hybrid work Experience, Programmer, Thinking
              out-of-the-box.
            </p>
          </div>
          <div className="team-items">
            <img src={require("../img/lady.png")} alt="lady" />
            <h3>Opeyemi Bamiduro</h3>
            <p>
              CEO of xHero, Hybrid work Experience, Programmer, Thinking
              out-of-the-box.
            </p>
          </div>
          <div className="team-items">
            <img src={require("../img/guy.png")} alt="guy" />
            <h3>Opeyemi Bamiduro</h3>
            <p>
              CEO of xHero, Hybrid work Experience, Programmer, Thinking
              out-of-the-box.
            </p>
          </div>
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="work">
        <h1>Want to work with us?</h1>
        <p>
          We are always looking for people who are passionate about our mission,
          learn more to see if xHero is right for you.
        </p>
        <a href="#">Explore Opening</a>
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg-2 hidden md:block">
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#F0F0FF"
        />
      </svg>
    </div>
  );
};

export default About;
