import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";

const Index: React.FC = () => {
  return (
    <div>
      <section className="bg-[#0D0D0D] flex flex-col items-center justify-center text-center h-[600px] mt-20 relative overflow-hidden">
        <h1 className="text-white font-bold text-5xl leading-tight p-4 z-10 animate-slide-in-left">
          Empowering Excellence, Celebrating You
        </h1>
        <p className="text-white max-w-lg p-4 leading-6 animate-slide-in-left">
          Be the HERO through excellence, engagement, and productivity with our
          employee recognition platform.
        </p>
        <div className="flex gap-4 mt-8 animate-slide-in-left">
          <Link
            to={"https://app.getxhero.com/login"}
            className="bg-[#6666FF] hover:bg-[#4014bb] text-white rounded-md w-48 h-12 font-bold flex items-center justify-center">
            Login
          </Link>
          <Link
            to={"/contact"}
            className="bg-[#6666FF] hover:bg-[#4014bb] text-white rounded-md w-48 h-12 font-bold flex items-center justify-center">
            Book a Demo
          </Link>
        </div>

        <img
          src="/img/hero.png"
          alt="Hero"
          className="hidden md:block absolute right-0 bottom-0 h-full object-contain animate-slide-in-right"
        />
      </section>

      <section className="bg-[#0D0D0D] text-white flex justify-center items-center py-4">
        <h3 className="text-lg text-center ml-4 mr-4 font-bold">
          Trusted and loved by 100+ businesses around the industries.
        </h3>
      </section>
      <section className="bg-[#0D0D0D] py-8 overflow-hidden">
        <div className="flex justify-center items-center gap-8 animate-scroll-horizontal">
          <img
            src="/img/first bank.png"
            alt="Firstbank"
            className="brand-items h-10"
          />
          <img
            src="/img/coke.png"
            alt="Cocacola"
            className="brand-items h-10"
          />
          <img
            src="/img/amazon.png"
            alt="Amazon"
            className="brand-items h-10"
          />
        </div>
      </section>

      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="py-12">
        <div className="text-center">
          <h1 className="text-3xl text-black font-bold">
            Build a Team of Groundbreakers
          </h1>
          <p className="text-black max-w-2xl mx-auto mt-4 p-5">
            At xHero, we are dedicated and committed to acknowledging and
            appreciating the efforts, achievements, and contributions of
            employees.
          </p>
        </div>

        {/* Container with animated boxes */}
        <div className="flex flex-col md:flex-row justify-center items-center md:space-x-12 mt-12">
          <div className="animate-slide-up flex flex-col items-center text-center bg-white hover:bg-[#6666FF] text-black hover:text-white p-10 max-w-xs mb-8 md:mb-0 rounded-lg shadow-lg">
            <img src="/img/star.png" alt="star" className="mb-4" />
            <h3 className="text-xl font-bold">Social Recognition</h3>
            <p className="mt-2">
              Recognizing your employees' hard work and contributions is crucial
              for building a positive, engaged workplace. xhero makes it easy to
              share wins, milestones, and shoutouts with the whole team,
              fostering a culture of appreciation. A little social recognition
              can go a long way in boosting morale and making your employees
              feel valued.
            </p>
          </div>
          <div className="animate-slide-up flex flex-col items-center text-center bg-white hover:bg-[#6666FF] text-black hover:text-white p-10 max-w-xs mb-8 md:mb-0 rounded-lg shadow-lg">
            <img src="/img/scale.png" alt="scale" className="mb-4" />
            <h3 className="text-xl font-bold">Data-Informed Decisions</h3>
            <p className="mt-2">
              We bring together all the important data from reviews, sales
              reports, and customer feedback to make better decisions. This
              helps us spot areas for improvement easily. Our reports are clear,
              accessible, and straightforward.
            </p>
          </div>
          <div className="animate-slide-up flex flex-col items-center text-center bg-white hover:bg-[#6666FF] text-black hover:text-white p-10 max-w-xs mb-8 md:mb-0 rounded-lg shadow-lg">
            <img src="/img/trade.png" alt="trade" className="mb-4" />
            <h3 className="text-xl font-bold">Engagement</h3>
            <p className="mt-2">
              Empower your company to show genuine appreciation for employees
              which can help foster a culture where every team member feels
              valued and recognized for their contributions.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-black py-24 px-12 md:px-24">
        <h3 className="text-white text-center font-bold text-3xl mb-12">
          Prioritizing Employee Wellbeing for a Thriving Company
        </h3>
        <div className="flex flex-col md:flex-row md:px-40 md:space-x-6 items-center justify-center">
          <img
            src="/img/unity.jpg"
            alt="unity"
            className="hidden md:block md:w-[500px] w-80 h-auto mb-6 md:mb-0 md:mr-8 rounded-lg"
          />
          <div className="text-white text-center md:text-left flex flex-col items-center md:items-start">
            <p className="mb-4 text-xl">
              Healthy, happy employees drive company success. We offer an
              engaging wellbeing program with fun challenges, on-demand content,
              and support for mental, physical, and financial wellness.
            </p>
            <Link
              to="/platforms"
              className="bg-[#6666FF] text-white rounded-md w-48 h-12 font-bold flex items-center justify-center">
              Explore Platform
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12">
        <h3 className="text-black text-center text-4xl font-bold mb-8">
          Built-in Integration
        </h3>
        <div className="flex flex-col md:flex-row items-center justify-center md:space-x-24">
          <div className="text-black text-center text-lg flex flex-col items-center max-w-md px-6">
            <p className="mb-4">
              Our platform works with your current systems, so you don't need to
              worry about manual processes or replacing your existing software.
              We integrate seamlessly with your HRIS and payroll systems,
              enhancing your employees' experience without disrupting your
              current tech setup.
            </p>
            <Link
              to="/about"
              className="text-[#6666FF] w-48 h-12 flex items-center justify-center font-bold border border-[#6666FF] rounded-lg">
              Learn More <i className="fas fa-arrow-right ml-2"></i>
            </Link>
          </div>
          <img
            src="/img/brands.png"
            alt="brands"
            className="md:w-[500px] w-80 h-auto mt-6 md:mt-0 md:ml-8"
          />
        </div>
      </section>

      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 0 1080 0C1300.23 0 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#0D0D0D"
        />
      </svg>

      <section className="bg-[#0D0D0D] py-12">
        <h3 className="text-white text-center font-bold text-2xl mb-8">
          Experience it through the Word
        </h3>
        <div className="flex flex-col md:flex-row justify-center gap-8 mx-10 md:mx-40">
          <div className="text-left text-white bg-[#3A3A3A] p-10 rounded-lg relative">
            <h3 className="text-xl">Sarah A.</h3>
            <span className="flex justify-start text-green-500">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </span>
            <p className="mt-4">"Rewarding Experience Platform"</p>
            <p className="mt-2 mb-10">
              "This platform is fantastic! It provides incentives that truly
              inspire me to get involved. The rewards are meaningful and make me
              feel valued as a participant. It's not just about the perks; It's
              about the engaging experience it creates. I highly recommend it."
            </p>
            <img
              src="/img/icon.png"
              alt="icon"
              className="flex justify-end absolute right-3 bottom-3"
            />
          </div>
          <div className="text-left text-white bg-[#3A3A3A] p-10 rounded-lg relative">
            <h3 className="text-xl">Sarah A.</h3>
            <span className="flex justify-start text-green-500">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </span>
            <p className="mt-4">"Rewarding Experience Platform"</p>
            <p className="mt-2 mb-10">
              "This platform is fantastic! It provides incentives that truly
              inspire me to get involved. The rewards are meaningful and make me
              feel valued as a participant. It's not just about the perks; It's
              about the engaging experience it creates. I highly recommend it."
            </p>
            <img
              src="/img/icon.png"
              alt="icon"
              className="flex justify-end absolute right-3 bottom-3"
            />
          </div>
          <div className="text-left text-white bg-[#3A3A3A] p-10 rounded-lg relative">
            <h3 className="text-xl">Sarah A.</h3>
            <span className="flex justify-start text-green-500">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </span>
            <p className="mt-4">"Rewarding Experience Platform"</p>
            <p className="mt-2 mb-10">
              "This platform is fantastic! It provides incentives that truly
              inspire me to get involved. The rewards are meaningful and make me
              feel valued as a participant. It's not just about the perks; It's
              about the engaging experience it creates. I highly recommend it."
            </p>
            <img
              src="/img/icon.png"
              alt="icon"
              className="flex justify-end absolute right-3 bottom-3"
            />
          </div>
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="py-12 md:py-24">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-center">
          <img
            src="/img/Rectangle 4367.png"
            alt="brands"
            className="md:w-[300px] w-80 h-auto mb-6 md:mb-0 md:mr-8"
          />
          <div className="text-black text-center md:text-left flex flex-col items-center md:items-start">
            <h2 className="text-3xl mb-4">Unleash the Hero Within</h2>
            <p className="mb-4 text-lg">
              Start Recognizing Greatness with xHero Today! Let’s make every win
              count—together.
            </p>
            <Link
              to="/about"
              className="bg-blue-600 text-white rounded-md w-48 h-12 font-bold flex items-center justify-center">
              Learn More
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
